import React from "react";
import { graphql } from "gatsby";
import Blog from "@rentivo/gatsby-theme-barcelona/src/templates/Blog/component";

export default Blog;

export const pageQuery = graphql`
  query GET_POSTS($ids: [String], $localeCode: WpLanguageCodeEnum) {
    allWpCategory(
      limit: 50
      filter: {
        language: { code: { eq: $localeCode } }
        parent: { id: { eq: null } }
        postCategory: { dps: { makeDps: { ne: true } } }
      }
      sort: { order: DESC, fields: count }
    ) {
      nodes {
        id
        name
        slug
        translations {
          id
          name
          slug
          language {
            slug
            locale
            code
          }
        }
        ancestors {
          nodes {
            id
            name
            slug
            translations {
              id
              name
              slug
              language {
                slug
                locale
                code
              }
            }
          }
        }
      }
    }
    allWpPost(limit: 100, filter: { id: { in: $ids } }) {
      nodes {
        ...PostListFields
        translations {
          ...PostListFields
        }
      }
    }
  }
`;
